import React, { Fragment, useState } from 'react';
import PageHero from './PageHero';
import CircleRings from './CircleRings';
// import Mailto from 'react-protected-mailto';
import WorkWithUsHero from '../images/heros/about-us-hero.jpg';
import People from '../images/work-with-us-people.jpg';
import Button from './Button';
import { Link } from 'react-router-dom';


import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';


const API_PATH = 'https://cms.awaypropertymanagement.com/api/workwithus/index.php';

export default function WorkWithUs(props) {
	const [toggleState, setToggleState] = useState(false);
	/* Yup validation schema */
	const SignupSchema = Yup.object().shape({
		firstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Your first name is required'),
		lastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Your last name is required'),
		email: Yup.string()
			.email('Invalid email')
			.required('Your email address is required'),
	});

	function randomDelay() {
		const rand = Math.random() * 3;
		// console.log(`rand: ${rand}`);
		return rand;
	}
	function toggle() {
		setToggleState(toggleState === false ? true : false);
		// console.log('toggling');
	}
	return (
		<Fragment>
			<PageHero
				bg_color="#fbf9fb"
				txt_color="black"
				height=""
				title="Work With Us"
				source={WorkWithUsHero}
			/>
			<div className="circle-bg-wrapper">
				<CircleRings
					id="rings-about-1"
					delay={randomDelay()}
				/>
				<CircleRings
					id="rings-about-2"
					delay={randomDelay()}
				/>
			</div>
			<div
				id="main-work-with-content-wrapper" className="main-content-wrapper">
				{/* <CircleRings /> */}
				<div id="main-work-with-content-block-wrapper" className="main-content-block-wrapper">

					<div id="main-wrapper" className="card">
						<img src={People} alt=""/>

						<h2>Owner or Investor looking for a Professional Management Company?</h2>

						<div className="form-wrapper"> {/* FORM WRAPPER */}

							<Formik
								initialValues={{
									firstName: '',
									lastName: '',
									companyName: '',
									startDate: '',
									leaseTermLength: '',
									services: '',
									properties: '',
									email: '',
									phoneNumber: '',
								}}
								validationSchema={SignupSchema}
								onSubmit={(values, { setSubmitting }) => {
									axios({
										method: 'post',
										url: `${API_PATH}`,
										headers: { 'content-type': 'application/json' },
										data: values
									})
										.then(result => {
											// console.log('end');
											// console.dir(values);
											// console.dir(result);
											toggle();
											setTimeout(() => {
												setSubmitting(false);
												document.getElementById("contact-form").reset();
											}, 400);
										})
										.catch(error => console.log(error.message));

								}}
							>
								{({ isSubmitting, errors, touched }) => (
									<Form id="contact-form">
										<div className="form-group">

											<div className="row">
												<div className="field-block">
													<label htmlFor="firstName">First Name</label>
													<Field
														type="text"
														id="firstName"
														name="firstName"
													/>
													{errors.firstName && touched.firstName ? (
														<small className="error">{errors.firstName}</small>
													) : null}
												</div>

												<div className="field-block">
													<label htmlFor="lastName">Last Name</label>
													<Field type="text" id="lastName" name="lastName" />
													{errors.lastName && touched.lastName ? (
														<small className="error">{errors.lastName}</small>
													) : null}
												</div>
											</div>

											<div className="field-block">
												<label htmlFor="companyName">Company Name (if applicable)</label>
												<Field
													type="text"
													id="companyName"
													name="companyName"
												/>
												{errors.companyName && touched.companyName ? (
													<small className="error">{errors.companyName}</small>
												) : null}
											</div>

											<div className="row">
												<div className="field-block">
													<label htmlFor="startDate">Start date to hire a management company?</label>
													<Field type="text" id="startDate" name="startDate" />
													{errors.startDate && touched.startDate ? (
														<small className="error">{errors.startDate}</small>
													) : null}
												</div>

												<div className="field-block">
													<label htmlFor="leaseTermLength">Length of Lease Term you are looking for?</label>
													<Field
														type="text"
														id="leaseTermLength"
														name="leaseTermLength"
													/>
													{errors.leaseTermLength && touched.leaseTermLength ? (
														<small className="error">{errors.leaseTermLength}</small>
													) : null}
												</div>
											</div>

											<div className="field-block">
												<label htmlFor="services">Services you are looking for?</label>
												<Field
													type="text"
													id="services"
													name="services"
												/>
												{errors.services && touched.services ? (
													<small className="error">{errors.services}</small>
												) : null}
											</div>


											<div className="field-block">
												<label htmlFor="properties">How many Properties do you want to be managed?</label>
												<Field type="text" id="properties" name="properties" />
												{errors.properties && touched.properties ? (
													<small className="error">{errors.properties}</small>
												) : null}
											</div>

											<div className="row">
												<div className="field-block">
													<label htmlFor="email">Email Address</label>
													<Field
														type="email"
														id="email"
														name="email"
													/>
													{errors.email && touched.email ? (
														<small className="error">{errors.email}</small>
													) : null}
												</div>

												<div className="field-block">
													<label htmlFor="phoneNumber">Phone Number</label>
													<Field type="text" id="phoneNumber" name="phoneNumber" />
													<ErrorMessage name="phoneNumber" component="div" />
												</div>
											</div>

											<div className="button-wrapper">
												<button type="submit" disabled={isSubmitting}>
													Send
												</button>
											</div>
										</div>

									</Form>
								)}
							</Formik>

						</div> {/* form-wrapper END */}
						<div>
							{toggleState &&
								<div style={{
									"textAlign": "center",
									"marginBottom": "40px",
								}}>
									<h2>Thank you for contacting us.</h2>
								</div>
							}
						</div>

					</div> {/* main-wrapper END */}

					<div className="bottom-button-wrapper">
						<Link to="about-us">
							<Button
								bg_color="#8cb5d8"
								txt_color="#1d1d1d"
								height="50px"
								label="Looking for Housing?"
								icon_style="fad"
								icon_name="house-day"
							/>
						</Link>
					</div>
				</div> {/* main-work-with-content-block-wrapper END */}
			</div> {/* main-work-with-content-wrapper END */}

		</Fragment>
	)
}
