import React, { Fragment, useState } from 'react'
// import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import PageHero from './PageHero'
import Button from './Button.js';
import CircleRings from './CircleRings'
// import Button from './Button'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import ResidentsHero from '../images/heros/residents-hero.jpg'

const API_PATH = 'https://cms.awaypropertymanagement.com/api/workorder/index.php';

export default function Residents(props) {
	const [toggleState, setToggleState] = useState(false);

	/* Yup validation schema */
	const SignupSchema = Yup.object().shape({
		firstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Your first name is required'),
		lastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Your last name is required'),
	});

	function randomDelay() {
		const rand = Math.random() * 3;
		// console.log(`rand: ${rand}`);
		return rand;
	}
	function toggle() {
		setToggleState(toggleState === false ? true : false);
		// console.log('toggling');
	}
	return (
		<Fragment>
			<PageHero
				bg_color="#fbf9fb"
				txt_color="black"
				height=""
				title="Residents"
				source={ResidentsHero}
			/>
			<div className="circle-bg-wrapper">
				<CircleRings
					id="rings-about-1"
					delay={randomDelay()}
				/>
				<CircleRings
					id="rings-about-2"
					delay={randomDelay()}
				/>
			</div>
			<div id="main-about-content-wrapper" className="main-content-wrapper">
				{/* <CircleRings /> */}
				<div id="main-about-content-block-wrapper" className="main-content-block-wrapper t-mar-80">
					{/* <div id="description-content-block-wrapper">
						<p>Need Maintenance?</p>
					</div> */}
					<div id="main-contact-wrapper" className="card">
						<h2 id="contact-header" style={{fontSize: "26px"}}>Need Maintenance?</h2>
						<div id="contact-instructions">Use the form below to get in touch. Our office hours are Monday through Friday, 9am to 5pm Central, and we attempt to respond to support requests within 1 business day.</div>

						<div className="form-wrapper"> {/* FORM WRAPPER */}

							<Formik
								initialValues={{
									firstName: '',
									lastName: '',
									address: '',
									date: '',
									message: ''
								}}
								validationSchema={SignupSchema}
								onSubmit={(values, { setSubmitting }) => {
									axios({
										method: 'post',
										url: `${API_PATH}`,
										headers: { 'content-type': 'application/json' },
										data: values
									})
										.then(result => {
											// console.log('end');
											// console.dir(values);
											// console.dir(result);
											toggle();
											setTimeout(() => {
												setSubmitting(false);
												document.getElementById("contact-form").reset();
											}, 400);
										})
										.catch(error => console.log(error.message));

								}}
							>
								{({ isSubmitting, errors, touched }) => (
									<Form id="contact-form">
										<div className="form-group">
											<div className="row">
												<div className="field-block">
													<label htmlFor="firstName">First Name</label>
													<Field
														type="text"
														id="firstName"
														name="firstName"
													/>
													{errors.firstName && touched.firstName ? (
														<small className="error">{errors.firstName}</small>
													) : null}
												</div>

												<div className="field-block">
													<label htmlFor="lastName">Last Name</label>
													<Field type="text" id="lastName" name="lastName" />
													{errors.lastName && touched.lastName ? (
														<small className="error">{errors.lastName}</small>
													) : null}
												</div>
											</div>

											<div className="row">
												<div className="field-block">
													<label htmlFor="address">Address</label>
													<Field
														type="text"
														id="address"
														name="address"
													/>
													{errors.address && touched.address ? (
														<small className="error">{errors.address}</small>
													) : null}
												</div>

												<div className="field-block">
													<label htmlFor="date">Date</label>
													<Field
														type="date"
														id="date"
														name="date"
													/>
													{errors.date && touched.date ? (
														<small className="error">{errors.date}</small>
													) : null}
												</div>
											</div>

											<label htmlFor="message">Message</label>
											<Field component="textarea" id="message" name="message" placeholder="What maintenance need or issues are you having?"/>
											<ErrorMessage name="message" component="div" />

											<div className="button-wrapper">
												<button type="submit" disabled={isSubmitting}>
													Send Message
												</button>
											</div>
										</div>

									</Form>
								)}
							</Formik>

						</div> {/* form-wrapper END */}
						<div>
							{toggleState &&
								<div style={{
									"textAlign": "center",
									"marginBottom": "40px",
								}}>
									<h2>Thank you for contacting us.</h2>
								</div>
							}
						</div>
					</div> {/* main-contact-wrapper END */}
				</div> {/* main-about-content-block-wrapper END */}
				<div id="pay-rent-wrapper">
					<a
						href="https://awaypropertymanagement.managebuilding.com/"
					>
						<Button
					name="submit"
					bg_color="#1d1d1d"
					txt_color="#dedac5"
					label="Pay Rent Online"
					icon_name="envelope-open-dollar"
					icon_style="fad"
					/>
					</a>
				</div>
			</div> {/* main-about-content-wrapper END */}

		</Fragment>
	)
}
