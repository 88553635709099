import React, { Component, Fragment } from 'react'
import axios from "axios";
import PageHero from './PageHero'
import CircleRings from './CircleRings'
import ListingCard from './ListingCard'
import ListingsHero from '../images/heros/listings-hero@2x.jpg'
import ImageGalleryModal from './ImageGalleryModal';


export default class Listings extends Component {
	constructor(props) {
		super(props)

		this.state = {
			listings: [],
			isLoaded: false,
			visible: 3,
			imageList: [],
			isImageModalOpen: false,
		}
		this.loadMore = this.loadMore.bind(this);
		this.openGallery = this.openGallery.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}
	openGallery(images) {
		// console.log('openGallery');
		// console.dir(images);
		this.setState({
			imageList: images,
			isImageModalOpen: true,
		});
		/* setTimeout(() => {
			console.log(this.state.imageList);
		}, 300) */

	}
	closeModal() {
		// console.log('closeModal');
		this.setState({
			imageList: [],
			isImageModalOpen: false,
		});
	}
	loadMore() {
		// console.log('load');
		this.setState((prev) => {
			return { visible: prev.visible + 3 };
		});
	}
	renderListings = () => {
		const { isLoaded } = this.state;
		if(!isLoaded) {
			return (
				// <LoadingAnimation/>
				<> </>
			)
		} else {
			// console.dir(listings);
			return (
				<Fragment>
					{this.state.listings.slice(0, this.state.visible).map((listing, index) => {
						return (
							<ListingCard
								key={listing.id}
								id={listing.id}
								address={listing.acf.address}
								city={listing.acf.city}
								state={listing.acf.state}
								zip={listing.acf.zip}
								availability={listing.acf.availability}
								deposit={listing.acf.deposit}
								bedrooms={listing.acf.bedrooms}
								square_footage={listing.acf.square_footage}
								bathrooms={listing.acf.bathrooms}
								pets={listing.acf.pets}
								furnished={listing.acf.furnished}
								off_street_parking={listing.acf.off_street_parking}
								public_transportation_within_a_mile_radius={listing.acf.public_transportation_within_a_mile_radius}
								garage={listing.acf.garage}
								rent={listing.acf.rent}
								gallery={listing.acf.gallery}
								openGallery={this.openGallery}
							/>
						);
					})}
					{(this.state.isImageModalOpen === true) && (
						<ImageGalleryModal
							images={this.state.imageList}
							closeModal={this.closeModal}
						 />
					)}
				</Fragment>
			)
		}
	}
	randomDelay = () => {
		const rand = Math.random() * 3;
		// console.log(`rand: ${rand}`);
		return rand;
	}
	getListings = async () => {
		/* Get page data via page slug */
		let res = await axios.get(
			`//cms.awaypropertymanagement.com/wp-json/acf/v3/listing?per_page=100`
		);
		let { data } = await res;

		this.setState({
			listings: data,
			isLoaded: true
		 });
	};
	componentDidMount = async () => {
		await this.getListings();
	};
	componentWillUnmount() {
		this.isLoaded = false;
	}
	render() {
		return (
			<Fragment>
				<PageHero
					bg_color="#fbf9fb"
					txt_color="black"
					height=""
					title="Available Listings"
					source={ListingsHero}
					/>
				<div className="circle-bg-wrapper">
					<CircleRings
						id="rings-listings-1"
						delay={this.randomDelay()}
					/>
					<CircleRings
						id="rings-listings-2"
						delay={this.randomDelay()}
					/>
					<CircleRings
						id="rings-listings-3"
						delay={this.randomDelay()}
					/>
				</div>
				<div id="main-listings-content-wrapper" className="main-content-wrapper">

					<div id="main-listings-content-block-wrapper" className="main-content-block-wrapper">
						{this.renderListings()}
						{/* <ListingCard /> */}
					</div>

					{this.state.visible < this.state.listings.length &&
						<button onClick={this.loadMore} type="button" className="fa-button">Load more</button>
					}
				</div>
			</Fragment>
		)

	}
}
