import React, { Fragment } from 'react'
import nav_data from '../data/site-nav.json';
import { Link, useLocation } from 'react-router-dom';
import Button from './Button.js';
export default function Nav(props) {
	const navListStyle = {
		margin: 0,
		listStyleType: "none",
	}

	let location = useLocation();

	function getSlug() {
		// console.dir(location.pathname);
		var res = location.pathname.split("/");
		// console.log(`res: ${res[1]}`);
		let slug = null;
		switch (res[1]) {
			case '':
				slug = 'home'; // page slug
				break;
			case 'apply':
				slug = 'listings'; // page slug
				break;
			case 'about-us':
				slug = 'about-us'; // page slug
				break;
			default:
				slug = res[1]; // page slug
		}
		return slug;
	}
	getSlug();

	return (
		<Fragment>
			<ul id={props.id} style={navListStyle}>
				{props.location}
				{nav_data.map(item => (
					<li key={item.id}>
						<Link

							className={
								((item.slug === '') ? 'home' : item.slug) +
								((item.slug === getSlug()) ? ' active' : '')
							}
							to={((item.slug === 'home') ? '/' : '/' + item.slug)}
							onClick={props.isPopup ? props.handleMenuClick : () => { }} // if in popup, close popup on click
						>{item.name}</Link>
					</li>
				))}
				<li>
					<a
						href="https://awaypropertymanagement.managebuilding.com/"
					>
						<Button
					name="submit"
					bg_color="#1d1d1d"
					txt_color="#dedac5"
					label="Pay Rent Online"
					icon_name="envelope-open-dollar"
					icon_style="fad"
					/>
					</a>
				</li>
			</ul>
		</Fragment>
	)
}

