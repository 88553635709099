import React, { Component } from 'react'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"


export default class ListingCard extends Component {
	convertToCurrency = (num) => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0
		})
		return formatter.format(num);
	}
	numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	imageClick = () => {
		// console.log('image click');
		this.props.openGallery(this.props.gallery);
	}


	render() {
		// console.dir(this.props.gallery);
		const image_main_thumb = this.props.gallery[0].sizes.medium_large;
		// console.log(`image_main_thumb: ${image_main_thumb}`);
		const availability_icon = (this.props.availability) ? "check-circle" : "times-circle";
		const encodedAddress = encodeURI(this.props.address);

		return (
			<motion.div
				className="listing-card-wrapper card"
				initial={{
					y: -20,
					opacity: 0,
				}}
				animate={{
					y: 0,
					opacity: 1,
				}}
				transition={{
					duration: 0.3,
					ease: "easeOut",
				}}
				>
				<div className="listing-card-image" onClick={this.imageClick}>
					<img src={image_main_thumb} alt={'Located at ' + this.props.address} />
				</div>
				<motion.div
					className="magnifying-glass"
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: 0.4,
					}}
					transition={{
						duration: 3,
						ease: "easeInOut"
					}}
				>
					<FontAwesomeIcon
						icon={['fas', 'search']}
						color='white'
						size='lg'
					>
					</FontAwesomeIcon>
				</motion.div>
				<div className="listing-attributes-wrapper">
					{/* Address */}
					<div className="listing-attribute-item" style={{ marginBottom: "14px" }}>
						<FontAwesomeIcon
							size="2x"
							icon={['fad', 'map-marked-alt']}
							color='#8cb5d8'
						/>
						<div className="address">{this.props.address}<br></br>
						{this.props.city}, {this.props.state} {this.props.zip}</div>
					</div>

					{/* Rent */}
					<div className="listing-attribute-item">
						<FontAwesomeIcon
							size="2x"
							icon={['fad', 'money-check-edit-alt']}
							color='#8cb5d8'
						/>
						<div className="rent line-item"><label>Rent: </label>{this.convertToCurrency(this.props.rent)}/mo</div>
					</div>

					{/* Deposit */}
					<div className="listing-attribute-item">
						<FontAwesomeIcon
							size="2x"
							icon={['fad', 'envelope-open-dollar']}
							color='#8cb5d8'
						/>
						<div className="deposit line-item"><label>Deposit: </label>{this.convertToCurrency(this.props.deposit)}</div>
					</div>

					{/* Availability */}
					<div className="listing-attribute-item">
						<FontAwesomeIcon
							size="2x"
							icon={['fas', availability_icon]}
							color='#8cb5d8'
						/>
						<div className="availability line-item">{(this.props.availability) ? "Available" : "Unavailable"}</div>
					</div>
					{this.props.public_transportation_within_a_mile_radius}
					{(this.props.square_footage ||
							this.props.bedrooms ||
							this.props.bathrooms ||
						 	this.props.pets ||
							this.props.furnished ||
							this.props.off_street_parking ||
							this.props.public_transportation_within_a_mile_radius ||
							this.props.garage
						 ) && (
						<div className={(this.props.public_transportation_within_a_mile_radius) ? "optional padded" : "optional"}>
							{/* Square Footage */}
							{(this.props.square_footage) && (
								<div className="listing-attribute-item">
									<FontAwesomeIcon
										size="2x"
										icon={['fad', 'ruler-triangle']}
										color='#4a4a4a'
									/>
									<div className="line-item"><label>Square Footage: </label>{this.numberWithCommas(this.props.square_footage)}</div>
								</div>
							)}
							{/* Bedrooms */}
							{(this.props.bedrooms) && (
								<div className="listing-attribute-item">
									<FontAwesomeIcon
										size="2x"
										icon={['fad', 'bed-alt']}
										color='#4a4a4a'
									/>
									<div className="line-item"><label>Bedrooms: </label>{this.props.bedrooms}</div>
								</div>
							)}
							{/* Bathrooms */}
							{(this.props.bathrooms) && (
								<div className="listing-attribute-item">
									<FontAwesomeIcon
										size="2x"
										icon={['fad', 'toilet']}
										color='#4a4a4a'
									/>
									<div className="line-item"><label>Bathrooms: </label>{this.props.bathrooms}</div>
								</div>
							)}
							{/* Garage */}
							{(this.props.garage) && (
								<div className="listing-attribute-item">
									<FontAwesomeIcon
										size="2x"
										icon={['fad', 'garage-car']}
										color='#4a4a4a'
									/>
									<div className="line-item"><label>Garage: </label>{(this.props.garage) ? "Yes" : "No"}</div>
								</div>
							)}
							{/* Off Street Parking */}
							{(this.props.off_street_parking) && (
								<div className="listing-attribute-item">
									<FontAwesomeIcon
										size="2x"
										icon={['fad', 'parking']}
										color='#4a4a4a'
									/>
									<div className="line-item"><label>Off Street Parking: </label>{this.props.off_street_parking}</div>
								</div>
							)}
							{/* Pets */}
							{(this.props.pets) && (
								<div className="listing-attribute-item">
									<FontAwesomeIcon
										size="2x"
										icon={['fad', 'dog-leashed']}
										color='#4a4a4a'
									/>
									<div className="line-item"><label>Pets: </label>{this.props.pets}</div>
								</div>
							)}
							{/* Furnished */}
							{(this.props.furnished) && (
								<div className="listing-attribute-item">
									<FontAwesomeIcon
										size="2x"
										icon={['fad', 'couch']}
										color='#4a4a4a'
									/>
									<div className="line-item"><label>Furnished: </label>{this.props.furnished}</div>
								</div>
							)}
							{/* Transportation */}
							{(this.props.public_transportation_within_a_mile_radius) && (
								<div className="listing-attribute-item public-transportation">
									<FontAwesomeIcon
										size="2x"
										icon={['fad', 'bus-alt']}
										color='#4a4a4a'
									/>
									<div className="line-item" style={{ width: "100%" }}><p className="label">Public transportation within a mile radius: Yes</p></div>
								</div>
							)}
						</div>
					)}
				</div>
				<div className="listing-button-wrapper">
					{this.props.availability && (
						<Link to={'./apply/' + this.props.id + '/' + encodedAddress}>
							<Button
								bg_color="#8cb5d8"
								txt_color="#fbf9fb"
								label="APPLY NOW"
								icon_name="clipboard-list"
								icon_style="fad"
							/>
						</Link>
					)}
				</div>
			</motion.div>
		)
	}
}
