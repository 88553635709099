import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet';
import axios from "axios";
import Button from './Button';
import CircleRings from './CircleRings';
import HomeCircle from './HomeCircle';
import LoadingAnimation from './LoadingAnimation';
import HomeHero from '../images/heros/home-hero.jpg';
import HomeHeroDesktop from '../images/heros/home-hero-desktop.jpg';
import HomeBlockOne from '../images/home-block-1@2x.jpg';
import HomeBlockTwo from '../images/home-block-2@2x.jpg';
import { Link } from 'react-router-dom';


export default class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			page_data: []
		};
	}
	getHelmet = () => {
		return(
			<Helmet>
				<title>Welcome to Away Property Management</title>
			</Helmet>
		)
	}


	getPage = async () => {
		/* Get page data via page slug */
		let res = await axios.get(
			`//cms.awaypropertymanagement.com/wp-json/wp/v2/pages?slug=home`
		);
		let { data } = await res;

		this.setState({ page_data: data });
	};

	randomDelay = () => {
		const rand = Math.random() * 3;
		// console.log(`rand: ${rand}`);
		return rand;
	}

	componentDidMount = async () => {
		await this.getPage();
	};

	render() {
		const { page_data } = this.state;
		// console.dir(page_data); // console out API page data
		if(page_data.length > 0) {
			return (
				<Fragment>
					{this.getHelmet()}
					<div className="circle-bg-wrapper">
						<HomeCircle id="houses-home-1"/>
						<CircleRings
							id="rings-home-1"
							delay={this.randomDelay()}
						/>
						<CircleRings
							id="rings-home-2"
							delay={this.randomDelay()}
						/>
					</div>
					{/* HOME HERO */}
					<div id="home-hero-wrapper">
						<img src={HomeHero} alt="hero" className="hero-image masked-element mobile"/>
						<img src={HomeHeroDesktop} alt="hero" className="hero-image masked-element desktop"/>
						<div id="home-hero-action1-wrapper">
							<div className="home-hero-headline" id="home-hero-headline1">
								<h1 className={"page-content"}>{page_data[0].acf.headline}</h1>
							</div>
							<div className="home-hero-button" id="home-hero-button1">
								<Link to="/work-with-us">
									<Button
										bg_color="#1d1d1d"
										txt_color="#dedac5"
										height=""
										label="Let's Get Started"
									/>
								</Link>

							</div>
						</div> {/* home-hero-action1-wrapper end */}
						<div id="home-hero-action2-wrapper">
							<div className="home-hero-headline" id="home-hero-headline2">
								<h2 className={"page-content"}>{page_data[0].acf.headline}</h2>
							</div>
							<div className="home-hero-button" id="home-hero-button2">
								<Button
									bg_color="white"
									txt_color="black"
									height=""
									label="Home Hero 2!!!"
								/>

							</div>
						</div>{/* home-hero-action2-wrapper end */}
					</div> {/* home-hero-wrapper end*/}

					<div id="home-main-wrapper"> {/* HOME MAIN*/}

						{/* HOME MAIN BLOCK 1*/}
						<div className="home-main-block-wrapper" id="home-main-block-1">
							<div className="main-content-block-wrapper" id="what-we-do">

								{/* image wrapper */}
								<div className="main-block-image">
									<img src={HomeBlockOne} alt="block one" className="action-image" />
								</div>

								{/* copy wrapper */}
								<div className="main-content-block card">
									<h2 className="page-content-header">What We Do</h2>
									<div className={"page-content"} dangerouslySetInnerHTML={{ __html: page_data[0].acf.what_we_do }} />
								</div>

							</div>
						</div>
						{/* HOME MAIN BLOCK 1 end*/}

						<div className="home-main-block-wrapper" id="home-main-block-2"> {/* HOME MAIN BLOCK 2*/}
							<div className="main-content-block-wrapper" id="how-different">
								<div className="main-block-image">
									<img src={HomeBlockTwo} alt="block two" className="action-image" />
								</div>
								<div className="main-content-block card">
									<h2 className="page-content-header">How We're Different</h2>
									<div className={"page-content"} dangerouslySetInnerHTML={{ __html: page_data[0].acf.how_were_different }} />
								</div>
							</div>
						</div>{/* HOME MAIN BLOCK 2 end*/}



					</div> {/* HOME MAIN ends*/}
						<div id="home-main-forms-wrapper"> {/* HOME FORMS*/}
							<div className="home-main-forms-header">Are you looking for forms?</div>
							<Link to="download-forms">
								<Button
								bg_color="#8cb5d8"
								txt_color="#1d1d1d"
									height="50px"
									label="Download Forms"
									icon_style="fad"
									icon_name="file-download"
								/>
							</Link>
						</div> {/* HOME FORMS end*/}
				</Fragment>
			)
		} else {
			return (
				<LoadingAnimation/>
			)
		}
	}
}
