import React, { Component } from 'react'
import Mailto from 'react-protected-mailto'

export default class ContactInfo extends Component {
	render() {
		return (
			<div id="contact-wrapper">
				<div className="contact-item address" id="address1">7235 Hidden Hollow Court,</div>
				<div className="contact-item address" id="address2">Mounds View, MN  55112</div>
				<div className="contact-item" id="phone-number"><Mailto tel='651-308-4969' /></div>
				<div className="contact-item" id="email-address">
					<Mailto
						email='leasing@awaypropertymanagement.com'
						headers={
							{ subject: 'Leasing question from the website' }
						}
					/>
					</div>
			</div>
		)
	}
}
